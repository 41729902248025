/*
 * Flex UX Framework
 *
 * Filename: terms.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.terms {
	main {
		background-color: #fff !important;
	}

	.page-body {
		max-width: 600px;
		margin: auto;
    	margin-top: auto;
		padding: 12px 12px 50px 12px;
	}

	h1{
		// font-family: $standard-font-face;
		margin-bottom: 10px;
		text-transform: capitalize;
		font-size: 23px;
		font-weight: 500; 
		color: #000;

		&:first-of-type{
			text-transform: capitalize; 
			font-size: 32px;
			font-weight: 700;
			color: #000;
		}
    }

    p {
    	font-size: 14px;
    	line-height: 1.5em;
    	margin-bottom: 20px;
    	text-transform: capitalize; 
	}
}