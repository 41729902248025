/*
 * Flex UX Framework
 *
 * Filename: checkout-payment-gateway-tabs.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-container .checkout-bottom--gateways .gateway-tabs .gateway-tabs__tab-bar {
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 14px;
    height: unset;
    min-height: 48px;
    
    .mdl-tabs__tab {
		font-size: 15px;
		font-weight: 700;  
		color: $primary-font-color;
		text-transform: uppercase;
        cursor: inherit;
        font-family: $standard-font-face;

        &::after{
            background: $brand-color;
            height: 3px;
        }
	} 
}
