/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.footer {
	display: flex;
	flex-flow: row nowrap;	
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	color: #fff;
	font-family: $standard-font-face;  
	font-size: 15px;
	font-weight: 400;
	height: 65px;  	
	min-height: 25px;
	padding: 0px 20px 0px 20px;
	background: $footer-background;

	border-top: 1px solid $brand-color-secondary;
	&-links {
		height: 100%;
	}

	a {
		display: flex;
		align-items: center;
		height: 100%;
		padding-left: 15px;
		padding-right: 15px;
    	text-decoration: none;
    	font-size: 14px;
    	color: $footer-link-color;
		font-weight: 700;

		&:hover {
			text-decoration: underline;
			color: $footer-link-color-hover;
		}	
	}

	.copyright{
		color: #FFF;
		font-size: 14px;
		font-weight: 700;
	}

	#call-link {
		display: none;
	}

	@media screen and (max-width: 740px) {
		.footer-nav-menu li {
			padding: 0px 8px;
		}
	}
	@media screen and (max-width: 865px) {
		padding: 30px 0px 30px 0px;
		font-size: 16px;
		height: unset;
		flex-flow: column nowrap;
		text-align: center;

		.copyright {
			margin-bottom: 12px;
		}

		ul.footer-nav-menu {
			display: flex;
			flex-direction: row;
			width: 90vw;
			justify-content: center;
			flex-wrap: wrap;

			li {
				padding: 10px 5px;
			}
		}

		#call-link{
			display: inline-flex;
			margin-bottom: 12px;
			
			a {
				display: inline-flex;
				text-decoration: none;
				font-size: 16px;
				

				&::before {
					content: '\e0cd';
					height: 30px;
					width: 18px;
					font-family: "Material Icons";
					font-size: 18px;
					color: #fff;  
					margin-right: 5px;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

