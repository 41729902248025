/*
 * Flex UX Framework
 *
 * Filename: myaccount-billingaddress.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.my-account{
    .mdl-textfield {

    	&__label {
    		text-align: center;
            color: #737677;
    	}

     	&.is-dirty {
        	color: $primary-font-color;

        	.mdl-textfield__label {
        		text-align: center;

                &::placeholder{
                }

        		&::after {
        			bottom: 20px;
        			content: '';
        			height: 2px;
        			left: 45%;
        			position: absolute;
        			transition-duration: .2s;
        			transition-timing-function: cubic-bezier(.4,0,.2,1);
        			visibility: hidden;
        			width: 10px;
        		}
        	}
        }
    }


    .mdl-textfield__input {
        padding-left: 3px;
        color: #737677;
    } 

	.select2-container--default .select2-selection--single .select2-selection__placeholder {
		color: #737677;
	}
}
