/*
 * Flex UX Framework
 *
 * Filename: category-header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 
.page-body {

	.products-header {
		.title {
			@include mq('tablet', 'max'){
				display:none;
			}
		}
	}
}


.specialCategory {
	background-color: #D8E6EF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	flex-grow: 1;
	order: -1;
	min-height: 150px;

	@include mq('phone-wide', 'max') {
		padding: 0 10px;
	}

	.specialTitle {
		color: $brand-color;
		font-size: 22px;
		font-weight: bold;
		padding: 14px 0;
	}

	.specialInstruction {
		font-weight: bold;
		padding-bottom: 16px;
		font-size: 14px;
	}

	.specialPara {
		text-align: center;
		font-size: 14px;
		padding-bottom: 16px;
		max-width: 640px;
	}
}