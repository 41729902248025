/*
 * Flex UX Framework
 *
 * Filename: spotlight_slider.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */ 

.page-header .hero-carousel-overlay  {
	width: 100%;
	height: 100%;
	max-height: 500px; 
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center; 
    left: 0;
    
	@include mq("desktop", "max") {
		max-height: 350px;
	}

	@include mq("tablet-small", "max") {
		max-height: 300px;
	}

	@include mq("phone", "max") {
		max-height: 275px;
	}

	img {
		position: absolute; 
	}	
	
}

.hero-carousel {
    width: 100%;
    max-width: 100%;
	min-height: 275px;
	max-height: 275px;

	margin-bottom: 0;

    .slick-slider .slick-track, .slick-slider .slick-list {
    	-webkit-transform: translate3d(0, 0, 0);
    	-moz-transform: translate3d(0, 0, 0);
    	-ms-transform: translate3d(0, 0, 0);
    	-o-transform: translate3d(0, 0, 0);
    	transform: translate3d(0, 0, 0);
	}

	.slick-track {
		opacity: 1;
		width: 1354px;
		position: relative;
		top: 0;
		left: 0;
		display: block;

		.hero-slide {
			//background-image: url("../images/spot-img.jpg")!important;
			height: 450px;
			width: 1354px;
			position: relative;
			left: 0px;
			top: 0px;
			z-index: 999;
			opacity: 1;
			background-position: center;
			background-size: cover;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
		}
	}

	.slick-track::before, .slick-track::after {
		display: table;
		content: '';
	}

}
