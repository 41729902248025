/*
 * Flex UX Framework
 *
 * Filename: custom-orders-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.custom-orders { 

	.page-body {

		p {
    		font-size: 14px;
    		line-height: 1.5em;
    		margin-top: 10px;
		}
		address {
			font-size: 14px;
			line-height: 21px;
			color: #000;
			font-family: "din-2014", sans-serif;
		}
	}
}