/*
 * Flex UX Framework
 *
 * Filename: checkout_completed.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-completed {
    .page-body {
        max-width: 600px !important;
     //   margin: 9px !important;    

        .checkout-container {
            margin: 10px 0px 10px 0px;
        }

        h1 { 
        //    margin-left: 5px !important; 
           margin-bottom: 0px; 
        }
    }
}