/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
//


$body-max-width:                    1184px;


//
// General: Color
// 

$brand-color:                       #00A1DF;  
$brand-color-secondary:             #D00A2C;
$primary-background-color:          #FFF;

$brand-color-btn-primary:           #1CACE9;
$brand-color-btn-primary-hover:     #109FDC;    
$brand-color-btn-secondary:         #2A2A2A;
$brand-color-btn-secondary-hover:   #000; 

$brand-color-tertiary:              pink; 

$primary-font-color:                #000; 
$secondary-font-color:              brown; 
$h1-font-color:                     #000;
$h2-font-color:                     $brand-color-secondary;  
  
$link-color:                        #D00A2C;
$link-color-hover:                  #C00F2D;  
 
//  
// General: Font 
// 
 
$h1-font-size:                      36px;

$h2-font-size:                      32px;

$standard-font-face:                'din-2014', sans-serif;
$secondary-font-face:               'din-2014', sans-serif;
$secondary-font-face-bold:          'din-2014', sans-serif;
$bold-font-face:                    'din-2014', sans-serif;
$drawer-font:                       'din-2014', sans-serif;

// 
// Header
//

$header-background:                 #FFF;
$header-top-background:             $brand-color;  
$header-height:                     140px; 
$header-top-height:                 40px;
$header-main-height:                100px;

$header-tablet-height:              56px; 
$header-phone-height:               56px; 
$header-logo-padding:               15px 15px 15px 20px;    

$header-logo-height:                59px;    
$header-logo-width:                 160px;  

$header-minicart-badge-background:  #D00A2C;
$header-minicart-badge-color:       #FFF; 
$header-minicart-badge-border:      1px solid #FFF;
$header-top-link-hover-border:      2px solid #000;
$header-icons-color:                $brand-color;
$header-search-font-color:          $primary-font-color;
$header-search-underline-color:     $brand-color-secondary;
 
$header-top-font-color:             #FFF;
$header-main-font-color:            #383838;
$header-main-font-color-hover:      #FFF;     
$header-main-nav-background-hover:  $brand-color; 

      
// 
// Mobile Drawer Menu
// 
 
$drawer-mobile-background:          #FFF;  


//
// Footer
// 

$footer-background:                 #2A2A2A; 
$footer-link-color:                 #FFF;
$footer-link-color-hover:           #FFF; 


//Icons

$icon-primary: #2A2A2A;
$icon-secondary: $brand-color;

//Font
$main-font-color: #000;