/*
 * Flex UX Framework
 *
 * Filename: category_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.sub-nav {

	.sub-nav-item {
    	position: relative;

	}

	.nav-handle {
		padding: 0 24px;
	}

}

