/*
 * Flex UX Framework
 *
 * Filename: price.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.price {
//	font-weight: 700;
//	font-family: Gotham-600; 
}  