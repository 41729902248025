/*
 * Flex UX Framework
 *
 * Filename: item-header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {

	&-details-header, &-title {
		font-size: 18px;
		line-height: 1.15;
		height: auto; 
		font-family: $secondary-font-face; 

	}

	&-title {
		color: $brand-color-secondary;
		font-size: 22px;
    	font-family: $bold-font-face;  
    	font-weight: 700;
	}


	&-details-header {
	  	border-bottom: 1px solid #EBEBEB;
		padding-bottom: 5px;

		.product-title, .product-price{
			font-weight: 700;
			font-family: $secondary-font-face;
			color: #000;
			text-transform: capitalize;
			@include mq("tablet", "max"){
				line-height: 23px;
			}
		}

		.product-sku{
			@include mq("tablet", "max"){
				padding-bottom: 10px;
			}
		}
	}

	&-sku { 
		font-weight: 400;
		font-size: 14px;
		padding: 10px 0px;
		color: $primary-font-color;
		text-transform: uppercase;
		line-height: 1;
		font-family: $secondary-font-face;
	}

	&-price {
		font-weight: 600;
		font-size: 21px;
		line-height: 23px;
		display: inline-block;
		text-transform: uppercase;
		width: 48%;
		font-family: $standard-font-face;    

		.price { 
			font-size: 20px;
			color: $brand-color-secondary;
			font-weight: 700; 
			font-family: $standard-font-face;
			text-transform: uppercase;
		}
	}

	&-size-chart-toggle {
    	text-align: right;
    	display: inline-block;
    	width: 49%;
    	font-size: 14px;
    	line-height: 1;

    	a{
			text-decoration: underline;
			font-size: 14px;
		}
	}
}
