// /*
//  * Flex UX Framework
//  *
//  * Filename: _mixins.scss
//  * Type:     Commonly Shared Stylesheet
//  * 
//  * Copyright (C) 2019 Geiger, All Rights Reserved
//  */


// @mixin primary-text {
//     color: $primary-text-color;
//     font-family: $standard-font-face;
// }

// @mixin header-primary-text {
//     color: $header-primary-text-color;
//     font-family: $standard-font-face;
// }

// @mixin page-header-text {
//     color: $secondary-text-color;
//     font-family: $standard-font-face;
//     font-size: 34px;
//     letter-spacing: 0;
//     font-weight: normal;
//     padding-top: 10px;
//     padding-bottom: 10px;
// }

// @mixin page-body-text {
//     font-size: 14px;
//     line-height: 21px;
//     color: $secondary-text-color;
// }

// // Note, this mixin must be used on the icon's container
// @mixin set-mdl-icon-size($size, $horizontal-offset: -12, $vertical-offset: -12) {

//     width: #{$size + 7}px;
//     height: #{$size + 7}px;

//     .material-icons {
//         font-size: $size+px;
//         width: $size+px;
//         height: $size+px;
//         transform: translate(#{$horizontal-offset}px, #{$vertical-offset}px);
//     } 
// }

// @mixin pt-serif {
//     font-family: 'PT Serif';
//     line-height: 1.8;
//     letter-spacing: 1px;
//     @include mq('tablet', 'max') {
//         line-height: 2;
//     }
// }

// // Mixins from http://engageinteractive.co.uk/blog/top-10-scss-mixins

// @mixin pseudo($display: block, $pos: absolute, $content: ''){
//     content: $content;
//     display: $display;
//     position: $pos;
// }

// @mixin responsive-ratio($x, $y, $pseudo: false) {
//     $padding: unquote( ( $y / $x ) * 100 + '%' );
//     @if $pseudo {
//         &:before {
//             @include pseudo($pos: relative);
//             width: 100%;
//             padding-top: $padding;
//         }
//     } @else {
//         padding-top: $padding;
//     }
// }

// @mixin truncate($truncation-boundary) {
//     max-width: $truncation-boundary;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

// // Tint and shade from https://css-tricks.com/snippets/sass/tint-shade-functions/
// @function tint($color, $percentage) {
//   @return mix(white, $color, $percentage);
// }

// @function shade($color, $percentage) {
//   @return mix(black, $color, $percentage);
// }

// @mixin grid-no-outside-border {

//     display: flex;
//     flex-flow: row wrap;
//     overflow: hidden;

//     .grid-cell {

//         position: relative;
//         $cell-width: 230px;

//         box-sizing: border-box;
//         overflow: hidden;

//         display: flex;
//         flex-flow: column nowrap;
//         justify-content: space-between;;
//         flex-basis: $cell-width;
//         flex: 1 0 $cell-width;

//         // Hides right and bottom borders on the side
//         margin: 0 -1px -1px 0;

//         padding: 20px;
//         width: $cell-width;
//         height: 320px;
//         border-right: 1px solid $divider;
//         border-bottom: 1px solid $divider;
//         text-transform: uppercase;
//         text-align: center;
//         letter-spacing: 1px;
//         font-size: 13px;

//         .img-container {
//             margin: auto;
//             max-width: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//         }

//         .product-image {
//             display: block;
//             width: auto;
//             height: auto;
//             max-width: 175px;
//             max-height: 175px;
//             padding-top: 20px;
//             padding-left: 20px;
//             padding-right: 20px;
//             padding-bottom: 40px;
//             // max-height: 236px;
        
//         .title {
//             margin-top: 4px;
//         }

//         .name {
//           font-weight: 600;
//         }

//         .price {
//             margin-top: 4px;
//             color: $brand-accent;
//             font-weight: 600;
//         }

//         &.flexbox-helper {
//             height: 0 !important;
//             padding: 0 !important;
//             border: none !important;
//         }

//         &.title-cell {
//             .title-container {
//                 flex-grow: 1;
//                 display: flex;
//             }
//             .title {
//                 margin: auto;
//                 color: $brand-accent;
//             }
//             .cell-btn {
//                 padding: 0 16px;
//                 margin-bottom: 5px;
//                 letter-spacing: 1px;
//                 font-size: 13px;
//                 @include mq('phone-wide', 'max') {
//                     padding: 0 5px;
//                     font-size: 12px;
//                 }
//                 @include mq('phone', 'max') {
//                     font-size: 13px;
//                 }
//             }
//         }

//         @include mq('phone-wide', 'max') {
//             height: 238px;
//             width: 50%;
//             flex-basis: 50%;
//             font-size: 12px;

//             .product-image {
//                 max-height: 174px;
//                 max-width: 100%;
//             }
//         }

//         @include mq('phone', 'max') {
//             width: 100%;
//             flex-basis: 100%;
//             font-size: 13px;
//         }
//     }

//     &.border-bottom {
//         .grid-cell {
//             margin-bottom: 0;
//         }
//     }
// }

// @mixin grid-with-gutter {

//     // These styles are applied to .grid

//     display: flex;
//     flex-flow: row wrap;
//     overflow: hidden;

//     @include mq('phone','max') {
//       margin-left: $mobile-gutter;
//       margin-right: $mobile-gutter;
//     }

//     .grid-cell {

//         position: relative;
//         $cell-width: 230px;

//         // gutter
//         // margin: 12px;
//         margin: 0 $desktop-gutter (2* $desktop-gutter);

//         background: white;

//         box-sizing: border-box;
//         border-radius: 5px;
//         overflow: hidden;

//         display: flex;
//         flex-flow: column nowrap;
//         justify-content: space-between;
//         flex-basis: $cell-width;
//         flex: 1 0 $cell-width;
//         width: $cell-width;
//         text-transform: uppercase;
//         text-align: center;
//         font-size: 11px;

//         .img-container {
//             margin: auto;
//             max-width: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//         }

//         .product-image {
//             display: block;
//             width: auto;
//             height: auto;
//             max-width: 175px;
//             max-height: 175px;
//             padding-left: 20px;
//             padding-right: 20px;
//             padding-bottom: 20px;
//         }

//         .name {
//             color: $secondary-text-color;
//             line-height: 17px;
//         }

//         .title {
//             margin-top: 4px;
//         }

//         .price {
//             margin-top: 4px;
//             color: $brand-accent;
//             font-weight: 600;
//         }

//         &.flexbox-helper {
//             height: 0;
//             padding: 0;
//             margin-top: 0;
//             margin-bottom: 0;
//         }

//         &.title-cell {
//             .title-container {
//                 flex-grow: 1;
//                 display: flex;
//             }
//             .title {
//                 margin: auto;
//                 color: $brand-accent;
//             }
//             .cell-btn {
//                 padding: 0 16px;
//                 margin-bottom: 5px;
//                 letter-spacing: 1px;
//                 font-size: 13px;
//                 @include mq('phone-wide', 'max') {
//                     padding: 0 5px;
//                     font-size: 12px;
//                 }
//                 @include mq('phone', 'max') {
//                     font-size: 13px;
//                 }
//             }
//         }

//         @include mq('tablet', 'max') {
//             width: calc(50% - #{$desktop-gutter * 2}) !important;
//             flex-basis: calc(50% - #{$desktop-gutter * 2}) !important;
//         }

//         @include mq('phone-wide', 'max') {

//             height: 284px !important;
//             font-size: 12px !important;

//             &.flexbox-helper {
//                 height: 0 !important;
//             }

//             margin: $mobile-gutter;

//             // These rules are siblings here instead
//             // of nesting .product-image inside .img-container,
//             // to help make them easier to override
//             .img-container {
//                 padding-bottom: 4px;
//             }

//             .product-image {
//                 max-height: 174px !important;
//                 max-width: 100%;
//             }

//             .below-product-img {
//                 padding: 16px !important;

//                 .name,
//                 .price {
//                     font-size: 11px !important;
//                 }

//                 .name {
//                     height: 21px !important;
//                 }
//             }
//         }

//         @include mq('phone', 'max') {
//             width: 100% !important;
//             flex-basis: 100% !important;
//             font-size: 13px;

//             // At this breakpoint, the grid is one product wide
//             margin-left: 0;
//             margin-right: 0;
//         }
//     }
// }

// .grid {
//     @media (min-width: 1350px) {
//         .grid-cell.grid-cell {
// //             width: 20%;
// //             flex-basis: 20%;
//         } 
//     }
// }

// @mixin line-padding ($size: 3px) {
//     padding-top: $size;
//     padding-bottom: $size;
// }

// @mixin fixed-body-size {
//     max-width: $body-max-width;
//     margin: auto;
//     padding: $desktop-gutter;
//     margin-top: 9px;

//     @include mq('phone-wide', 'max') {
//         padding: $mobile-gutter;
//         margin-top: 0;
//     }
// }

// @mixin fixed-body-section {
//     max-width: $body-max-width;
//     padding: $desktop-gutter;

//     @include mq('phone-wide', 'max') {
//         padding: $mobile-gutter;
//     }
// }

// @mixin button-base {
//     padding: 0px 15px;
//     transition: background 0.15s;
//     font-size: 16px;
//     font-family: $standard-font-face;
// }

// @mixin button-dark-base {
//     background-color: #000;
// }

// @mixin button-light-base {
//     @include button-base();

//     border: 1px solid #F2F2F2;
//     background-color: #FFF;
//     height: 45px;

//     &:disabled {
//         color: #FFF;
//         border: 0;
//     }

//     &:hover {
//         background-color: #E8E8E8;
//     }

//     &:focus {
//         background-color: #C2C2C2;
//     }

//     .mdl-ripple {
//         background: #ADADAD !important;
//         background-color: #ADADAD !important;
//     }
// }

// @mixin button-primary-base {
//     @include button-base();

//     background-color: $brand-accent;
//     color: #fff;
//     height: 45px;

//     &:disabled {
//         color: #808080;
//         background-color: #CCCCCC;
//     }

//     &:hover {
//         // background-color: lighten($brand-accent, 4%);
//         background-color: #14AFD9;
//     }

//     &:focus {
//         // background-color: lighten($brand-accent, 6%);
//         background-color: #3DBCE0;
//     }

//     .mdl-ripple {
//         // background: lighten($brand-accent, 10%) !important;
//         // background-color: lighten($brand-accent, 10%) !important;
//         background: #51C4E3 !important;
//         background-color: #51C4E3 !important;
//     }

// }

// @mixin button-secondary-base {
//     @include button-base();

//     background-color: #53565A;
//     color: #fff;
//     height: 45px;

//     &:disabled {
//         color: #808080;
//         background-color: #CCCCCC;
//     }

//     &:hover {
//         background-color: #606367;
//     }

//     &:focus {
//         background-color: #7c7e81;
//     }

//     .mdl-ripple {
//         background: #8a8c8e !important;
//         background-color: #8a8c8e !important;
//     }
// }
