/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 /*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// .spotlight {
// 	// height: clamp(200px, 30vw, 350px);
// 	height: clamp(150px, 30vw, 178px);
// 	border-bottom: 4px solid $brand-color;

// 	.wrapper{
// 			position: relative;
// 			width: 100%;
// 			height: 100%;
			
// 			.content{
// 				background: url("../images/spotlight_slice.jpg") repeat-x;
// 				width: 100%;
// 				height: 100%;
// 				padding: 0px 30px;
// 				padding-top: clamp(24px, 10vw, 40px);

// 				.spotlight_logo {
// 					background: url("../images/cmta_logo_rev.svg") left no-repeat;					
// 					height: 90px;
// 			}
// 		}
// 	}
// }

.spotlight {
	height: clamp(250px, 30vw, 350px);
	border-bottom: 4px solid $brand-color;
	.wrapper{
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		background-image: url("./../images/HOME_desktop_350_1024.jpg");
		@include mq("desktop", "max") {
			background-image: url("./../images/HOME_tablet_350_768.jpg");
		}
		@include mq("750-pixels", "max") {
			background-image: url("./../images/HOME_mobile_300_400.jpg");
		}
		@include mq("600-pixels", "max") {
			background-image: url("./../images/HOME_mobile_small_250_321.jpg");
		}
		.content{
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 20px;
			.text{
				flex-basis: 65%;
				z-index: 1;
				margin-left: 50px;
				@include mq("600-pixels", "max") {
					margin-left: 20px;
				}
				h1{
					text-transform: capitalize;
					font-size: clamp(40px, 13vw, 90px);
					font-weight: 700;
					font-family: $secondary-font-face;
					margin: unset;
					color: #FFF;
					margin-top: 10px;
				}
				h2{
					font-size: clamp(20px, 5vw, 42px);
					font-weight: 700;
					text-transform: uppercase;
					font-family: $standard-font-face;
					margin: unset;
					color: $brand-color;

				}
			}
		}
	}
}