/*
 * Flex UX Framework
 *
 * Filename: basket-promo.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-basket-promo{
	/*padding: 20px;*/
	padding-left: 20px;
	padding-right: 20px;
}