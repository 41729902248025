/*

 * Flex UX Framework

 *

 * Filename: pagination.scss

 * Type:     Partial Stylesheet

 * 

 * Copyright (C) 2019 Geiger, All Rights Reserved

 */

.pagination{
	height: 80px !important;
	display: flex;
	justify-content:center;
	align-items: center;

	.material-icons{
		color: $brand-color-secondary;
	}

	.pagination-left{
		display: flex;
	}

	.pagination-numbers{
		display: flex;
		list-style-type: none;
		margin: 0px;
		align-items: center;
		justify-content: center;
		align-content: center;
		padding: 0px;

		li{
			display: flex;
			justify-content: center;
			align-items:center;
			margin: 0px 5px;
			font-family: $standard-font-face;
			
			&.is-active{
				background: $brand-color-secondary;
				;

				a {
					color: white;
					font-size: 15px;
					font-weight: 700;
				}
			}

			&:hover{
				background: $brand-color-secondary;

				a {
					color: white;
					font-size: 15px;
					font-weight: 700;
				}
			}

			//padding: 0 2px;
			width: 35px !important;
			height: 35px !important;
			font-size: 15px !important;

			a{
				text-decoration: none;
				color: $primary-font-color;
				font-weight: 700;

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					margin-top: 5px;
				}
			}

		}

	}

}