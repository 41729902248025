/*
 * Flex UX Framework
 *
 * Filename: terms.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.terms {
    .page-header {
        height: 0;
    }
        h1{
            margin-top: 30px;
            margin-bottom: 12px;
            text-transform: capitalize; 
        }
}