/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home-contact-us {
 	// margin-top: 30px;
	width: 100%;
	display: flex;
	height: 325px;
	justify-content: center;
	background-color: #000; 
	padding-bottom: 45px;
	padding-left: 15px;
	padding-right: 15px;
	z-index: 0;

	@include mq("tablet", "max") {
		height: 365px;
	}

	@include mq("500-pixels", "max") {
		height: 425px;
	}
	.globe {
		background-image: url('./../images/Globe.svg');
		width: 100%;
		height: 325px;
		position: absolute;
		z-index: 1;
		opacity: 0.23;
		background-repeat: no-repeat;
		background-size: 605px 595px;
		background-position: -195px -120px;
		@include mq("tablet", "max") {
			height: 365px;
		}
		@include mq("500-pixels", "max") {
			height: 425px;
			background-position: -195px -85px;
		}
	}

    &__content {
		text-align: center;
		padding-top: 45px;
		width: 100%;
		max-width: 950px;
		z-index: 2;
		@include mq("850-pixels", "max") {
			padding-top: 40px;
		}

		@include mq("700-pixels", "max") {
			padding-top: 25px;
		}

		@include mq("phone-wide", "max"){
	    	margin: 25px 12.5px;
	    }

		@include mq("500-pixels", "max") {
			padding-top: 10px;
		}

		// @include mq("phone", "max") {
		// 	// margin: 0;
		// 	padding: 0;
		// }

		.contact-us-grid {
			display: flex;
    		justify-content: space-between;
    		padding: 0px;
			gap: 2rem;
			@media screen and (max-width: 840px) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
				a, p {
					text-align: center;
				}
				&>div{
					align-items: center !important;
				}
			}
			& > div{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding: unset;
				margin: unset;
			}
    	}

		.title{
			text-align: left;
			margin-bottom: 10px;
			@media screen and (max-width: 840px) {
				text-align: center;
			}
		}
		h1 {
			margin: 0px;  
			margin-bottom: 25px;
			color: #FFF;
			font-family: $secondary-font-face;
			font-size: 36px;
			font-weight: 700;
			text-transform: capitalize;
			text-align: center;
			@media screen and (max-width: 758px){
				font-size: 30px;
			}
		}

    	p {
			text-align: left;
			font-family: $secondary-font-face;
			font-size: 16px;
			font-weight: 400;
			color: #FFF;
			margin-bottom: 10px;
			line-height: 1.5rem;

			@include mq("tablet", "max") {
				padding-right: 0px;
			}
		}

		a {
			color: #FFFFFF;
			font-size: 16px;
			text-align: left;
			color: $brand-color;

			&:hover{
				color: #109FDC;
			}
		}
    }
}