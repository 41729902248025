/*
 * Flex UX Framework
 *
 * Filename: product_card.scss
 * Type:     Component Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 // @TODO: factor media queries, convert to mixin, push margin attributes to parent
.page-body .mdl-tabs .products-tab-panels .grid-cell {
    max-height: 300px;
    width: 300px;
    max-width: 300px;
    flex-basis: 300px;
}

.page-body .grid .grid-cell.product-cell, 
.page-body .grid .grid-cell.new-cell {
	width: 215px;
	max-width: 300px; 
    flex-basis: 215px;  
	@include mq("tablet", "max"){
	    width: calc(50% - 24px);
	    flex-basis: calc(50% - 24px);
	}
}

.page-body .grid .grid-cell.product-cell:nth-child(n+5), 
.page-body .grid .grid-cell.new-cell:nth-child(n+5) {
	@include mq('phone-wide', 'max'){
	    display: none;
	}
}

.product-card, .product-cell {
	@include mq('tablet', 'max') {
	    width: calc(50% - 24px);
	    flex-basis: calc(50% - 24px);
	}

	@include mq('phone-wide', 'max') {
		font-size: 12px;
	}

	@include mq('phone', 'max') {
    	font-size: 13px;
	}


	border: 1px solid #EBEBEB;
	border-radius: 10px;
	position: relative;
    background: white;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex-basis: 215px; 
    flex: 1 0 215px;
    width: 215px;
    text-transform: uppercase;
    text-align: center;
    font-size: 11px;
    max-width: 215px;
	padding: 20px 14px;

    &--spotlight {
    	max-width: 200px;
	    padding: 30px;
	    height: 270px;

    	@include mq('phone-wide', 'max') {
			padding: 20px 10px 0 10px;
		}
	
		&:nth-child(n+5) {
			@include mq('phone-wide', 'max') {
				display: none;
			}
		}
    }

    a {
    	text-decoration: none;
    	cursor: pointer;
    	
		&:hover{
			color: $brand-color-secondary;
		}
	}

	.product-card-image {
		padding-bottom: 0;
		max-height: 184px;
		margin: auto;
	    max-width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;

	    &__image {

	    	@include mq('phone-wide', 'min') {
				max-height: 100%;
			}		

			max-width: 125px !important;
    		max-height: 125px !important;
			display: block;
			width: auto;
			height: auto;
			padding-bottom: 20px;
		}
	}

	.name {
		font-size: 15px;
		font-family: $standard-font-face; 
		text-transform: uppercase;
		color: #000; 
		font-weight: 700;
		text-align: center;
    	line-height: 1.15;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		letter-spacing: normal;
		margin-bottom: 10px;
		height: 49px;

	}

	.price {
		color: $brand-color-secondary;
		text-transform: uppercase;
		margin-top: 0px;
    	font-size: 14px;
		font-family: $standard-font-face;
		font-weight: 700;
	}
}

.product-cell {
    max-height: 304px;
    max-width: 300px;
    border-top: none;

    @include mq('phone-wide', 'min') {
    	padding: 30px;
	}

	@include mq('tablet', 'max') {
    	width: calc(50% - 24px) !important;
    	flex-basis: calc(50% - 24px) !important;
	}

	.img-container {
	    margin: auto;
	    max-width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    max-height: 184px;
	}
}
