/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: fixed;
	width: 100%;
	border-bottom: 1px solid #EBEBEB;
	background: $header-background;
	z-index: 99;
	height: $header-height;

	@include mq('tablet', 'max') {
		height: 56px;
	}

	.site-header {
		height: $header-top-height;
		background: $header-top-background;
		font-weight: 400;
		font-family: $standard-font-face;
		display: flex;
		justify-content: space-between;
		// justify-content: flex-end;
		align-items: flex-end;


		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
			margin: auto 0;
		}

		.budgetStoreLink {
			display: flex;
			align-items: center;
			height: 100%;
			margin-left: 25px;
			text-decoration: none;
			color: #FFF;
			font-size: 14px;
			font-weight: 700;
			&:hover {
				text-decoration: underline;
				
			}

		}

		&__welcome {
			padding-left: 158px;
			text-transform: none;
			font-size: 14px;
			font-weight: 700;
			color: $header-top-font-color;
			font-family: $standard-font-face;
			text-transform: capitalize;
		}

		&__nav-menu {
			margin: auto 0;
			padding-right: 25px;
			padding-left: 0px;

			li {
				display: inline-block;
				list-style-type: none;
				padding-left: 28px;
				cursor: pointer;

				a {
					color: $header-top-font-color;
					font-size: 14px;
					text-decoration: none;
					text-transform: capitalize;
					font-weight: 700;
					font-family: $standard-font-face;
				}

				&:hover {
					a{
						text-decoration: underline;
					}
				}

			}

		}

		.site-header__left {
			position: absolute;
			left: 0;
			top: 0;
			max-height: $header-top-height;

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}
		.literatureStore {
			color: #FFF;
			text-decoration: none;
			font-weight: 700;
			font-size: 14px;
			text-align: left;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		font-weight: normal;
		padding-left: 0px;
		padding-right: 24px;
		height: $header-main-height;
		font-family: $bold-font-face;
		background: $header-background;
		border-bottom: 1px solid #ebebeb;

		@include mq('tablet', 'max') {
			height: $header-tablet-height;
			padding-right: 49px;
			border-bottom: 1px solid #ebebeb;
		}

		@include mq('phone-wide', 'max') {
			height: $header-phone-height;
		}

		&__left, &__right {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.main-nav__right {
		flex-flow: row nowrap;
		.mdl-textfield--expandable {
			padding: 0;
			.mdl-textfield__expandable-holder{
				max-width: 0;
				overflow-x: hidden;
			}
			&.is-focused, &.is-dirty{
				.mdl-textfield__expandable-holder{
					max-width: 600px;
				}
			}
		}

		@include mq("tablet", "max") {
			margin-top: 3px;
		}

		.mdl-button--search {
			top: 0;
			bottom: 0;

			.material-icons {
				font-size: 24px;
				height: 24px;
				width: 24px;
			}

		}

		.mdl-textfield--expandable {
			padding: 0;
			display: flex;
			align-items: center;
		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: center;

		}

		.search-textfield-container {
			position: relative;

			i {
				color: $icon-primary;
			}

			.mdl-textfield__label::after, .mdl-textfield__label, .mdl-textfield__input {
				background-color: #fff;
				border-color: #757676;
				max-width: 150px;
			}

			.is-focused {
				.mdl-textfield__label::after {
					background-color: $header-search-underline-color ;
				}

				.mdl-textfield__input{
					color: $header-search-font-color;
					border-color: $header-search-underline-color ;
				}
			}

			#searchField {
				//border-bottom: #FFF;
			}

			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: $icon-primary;
			transform: translate(-12px, -11px);
		}

		.cart {
			margin-left: 8px;

			.cart-container {
				width: 34px;
				height: 34px;

				.material-icons::after {
					border: $header-minicart-badge-border;
					width: 12px;
					height: 12px;
					top: -3px;
					right: -1px;
					font-size: 9px;
					background: $header-minicart-badge-background;
					color: $header-minicart-badge-color;
				}

			}

			.mdl-menu {
				&__container {
					right: -15px;
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: $standard-font-face;

						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
							}

							.cart-title {
								font-size: 23px;
								text-transform: capitalize;
								line-height: 1.15;
								font-weight: 500;

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;

								.cart-item-img {
									display: flex;
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											font-weight: 700;
											font-size: 13px;
											line-height: 16px;
											text-decoration: none;
											color: $primary-font-color;
											
											&:hover{
												color: #333;
											}
										}

									}

									.cart-item-quantity {
										flex: 1 50%;
										font-weight: 400;
										font-size: 13px;
									}

									.cart-item-price {
										flex: 1 50%;
										color: $brand-color-secondary;
										
										.price{
											font-weight: 700;
											font-size: 13px;
										}
									}

								}

							}

						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							}

							.cart-subtotal {
								color: #333;
								font-size: 16px;
								font-weight: bold;

								@include mq('tablet', 'max') {
									font-size: 15px;
								}

								.price {
									font-weight: bold;
								}

							}

							// .checkout-btn:hover {
							// 	background-color: $brand-color-btn-primary-hover;
							// 	color: #fff;
							// 	text-decoration: none;
							// }

							.mdl-button--colored {
								font-family: $secondary-font-face;
								// font-size: 15px;
								// font-weight: 600;
								// letter-spacing: 1px;
								// line-height: normal;
								display: flex;
								align-items: center;
								// background: $brand-color;
								// color: white;
								// height: 45px;
								// text-transform: uppercase;

								@include mq('tablet', 'max') {
									font-size: 14px;
								}
							}

						}

					}

				}

			}

		}

	}

	.main-nav__left {
		@include mq('tablet', 'min') {
			.logoLink{
				margin-top: 0px;
				position: relative;
				z-index: 10;
			}
		}

		.mdl-button {
			transition:none;
		}

		@include mq('tablet', 'max') {
			margin-bottom: 0px;
		}

		.desktop-nav {

			@include mq('tablet', 'max') {
				display: none !important;
			}
			@include mq("1050-pixels", "max") {
				bottom: 0%;
				margin-left: 40px;
			}

			display: flex;
			flex-flow: row wrap;
			width: 100%;
			margin: 0px;
			// margin-left: calc(50vw - 246px);
			padding: 0px;
			position: absolute;

			.main-menu-nav{
				margin-top: 0px;
				padding: 0px;
				flex: 1 1 100%;
				display: flex;
				justify-content: center;
				margin: auto 0;
			}

			& > ul > li {
				position: relative;
				display: inline-block;
				list-style: none;

				.nav-handle {
					&:hover {
						background: #FFF;
						a {
							color: #000;
						}
					}
				}
			}

			& > ul > li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;
				min-width: 112px;

				&:nth-of-type(2) > div > a{
					padding: 10px 15px;
				}

				&:before {
					content: '';
					position: absolute;
					top: 30px;
					background-color: transparent;
					height: 40px;
					width: 190px;
				}
			
				.nav-handle {
					position: relative;
					margin-left: 0;
					padding-left: 0px;
				}


				> .nav-handle {

					::after {
						font-family: 'Material Icons';
						// content: '\e5d2';
						content: '\e5cb';
						display: inline-block;
						transform: rotate(-90deg);
						font-size: 24px;
						font-weight: normal;
						color: $brand-color-secondary;
						position: absolute;
						//top: 2px;
						clip: rect(6px, 22px, 32px, 0px);
					}

					// &:hover {
					// 	background-color: #005596;
					// 	a {
					// 		color: #FFF;
					// 	}
					// 	::after {
					// 		color: #FFF;
					// 	}
					// }
				}

				.sub-nav {
					z-index: 10;
					padding: 8px 0;
					display: none;
					opacity: 0;
					position: absolute;
					top: 35px;
					padding-top: 0;
					border-bottom-left-radius: 15px;
					border-bottom-right-radius: 15px;

					.sub-nav {
						top: 0;
						left: 1px;
						position: absolute;
					}

					.mdl-button {
						height: 40px !important;
					}

					.sub-nav-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: nowrap;
						text-align: left;
						padding-left: 0px;
					}

					.nav-handle {
						padding: 0 18px;

						&:hover {
							background-color: #EBEBEB;

							::after {
								color: #000;
							}
						}
					}

				}

				.sub-nav:first-of-type {
					padding-top: 8px;
					width: auto;
					border-radius: unset;
					;
					box-shadow: 0px 4px 8px rgba(0,0,0,.15);
				}

				.sub-nav [data-subnav] {
					& > .nav-handle {

						::after {
							font-family: 'Material Icons';
							content: '\e5cc';
							display: inline-block;
							font-size: 24px;
							color: $brand-color-secondary;
							position: absolute;
							right: 3px;
							//top: -3px;
							clip: rect(6px, 22px, 32px, 0px);
						}
					}
				}

			}

			& > ul > li {
				.nav-handle {
					border-radius: 0;
					display: flex;
					flex-flow: column;
					justify-content: center;
					height: 39px;

					& > a {
						text-transform: capitalize;
						font-size: 15px;
						line-height: 40px;
					}
				}

				a {
					color: #000; 
					text-transform: none;
					text-decoration: none; 
					font-family: $standard-font-face;
					font-size: 16px;
					text-align: left;
					font-weight: 700;
				}

				.nav-handle-line-1 a {
					font-size: 15px;
					font-weight: 700;
					color: $main-font-color;
					text-align: center;
					white-space: nowrap;
								
				}

				.nav-handle-line-1-1{
					margin-left: 20px;
					a{
						padding-left: 14px;
						padding-right: 16px;
					}
				}
			}

		}


		.logo {
			width: $header-logo-width;
			max-width: $header-logo-width;
			height: $header-logo-height;
			padding: 0px;
			margin: 0px 0px 0px 5px;
			object-fit: cover;

			@include mq('tablet', 'max') {
				height: 48px;
				margin: 0px 0px 0px 0px;
				width: 140px;
				max-width: 140px;
				top:0px;
				object-fit: fill;
			}
		}
	}
}

.mobile-drawer-nav {
	position: fixed;
	z-index: 100;
	pointer-events: none;
	font-family: $standard-font-face;

	.drawer-logo-container {
		margin-top: 0;
		height: 56px;
		border-bottom: solid 1px #EFEFF2;
		display: flex;

		.close-menu{
			position: absolute;
			right: 20px;
			top: 15px;
			color: $brand-color-secondary !important;
			cursor: pointer;
		}

		.logo--desktop {
			width: 140px;
			height: 48px;
			position: relative;
			top: 4px;
			
			@include mq("tablet", "min") {
				padding: 25px 5px 5px 0px;
			}
		}
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		font-weight: 400;
		border: none;
		text-transform: capitalize;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $icon-primary;
			top: 1px;
			left: auto;
			right: 0px;
			pointer-events: all;
		}
	}

	.mdl-navigation {
		padding-top: 0;
		font-size: 14px;

		ul {
			font-size: 15px;
			padding-left: 20px;

			li{
				a{
					text-decoration: none;
					text-transform: capitalize;

					span{
						font-weight: 500;
					}
				}
			}
		}

	}

	ul {
		font-size: 15px;
		
		.mobile-nav-handle {
			font-weight: 700;

			a {
				color: $primary-font-color;
				font-family: $standard-font-face;

				&:hover{
					color: $primary-font-color;
					text-decoration: underline;
				}
			}
		}

		[data-subnav] {
			i.material-icons {
				color: $brand-color-secondary;
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;
		}

	}

	ul.top-buttons {
		margin-bottom: 0;
		li.top-btn {
			a {	
				font-family: $bold-font-face;
				text-transform: capitalize;
				color: $brand-color-secondary;
				text-decoration: none;
				font-weight: 500;

				&:hover{
					color: $brand-color-secondary;
				}
			}
		}
	}
	.literatureStore {
		padding-left: 20px;
		text-decoration: none;
		font-weight: 500;
		font-size: 15px;
		text-align: left;
	}

	.back-button {
		cursor: pofinter;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;
		color: $primary-font-color;
		font-size: 15px;

		i.material-icons {
			color: $icon-primary;
			margin-right: 10px;
		}
	}

	.sub-nav-title {
		font-weight: 400;
		color: $primary-font-color;
		font-size: 15px;

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 25px;
			color: $icon-primary;
			margin-right: 6px;
		}
	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;

		.mdl-textfield {
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid #D9D9D9;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty, .mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $brand-color-secondary;
		}

	}
}