/*
 * Flex UX Framework
 *
 * Filename: message_box.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.message-box {
	display: block;
    width: 100%;
    text-align: center;
    display: block;
    width: 100%;
    text-align: center;
    max-width: 1115px;
    background-color: #c4df9b;
    border: 1px solid #197b30;
    margin: 25px auto 0 auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    a {
    	padding: 0px 5px 0px 5px;
    	
    }

	ul {
	    margin: 0;
	    display: inline-flex;
	}
	ul,  div,  p {
	    display: inline-block;
	    text-align: center;
	}

	li {
		font-size: 12px;
    	font-weight: 600;
	    padding-top: 0;
	    padding-bottom: 5px !important;
	    min-height: 0 !important;
	    display: inline-flex;
	}
	
	li, p {
    	color: $primary-font-color;
	}

	&.message-box--init-hidden {
    	display: none;
	}

	.mdl-list {
    	padding: 0 ;
	}

	&.message-box--success .material-icons {
	    font-size: 12px;
	    color: $primary-font-color;
	}

	&.message-box--warning{
		background-color: #ffafaf;
		a {
			color: red;
    		font-weight: 600;
		}

	}
}