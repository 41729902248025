/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home .main-feature {
    background-image: url("../images/banner-img.jpg");
    background-position: center right;
    min-height: 250px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
	@include mq("600-pixles", "max"){
		background-image: url("../images/banner-img-mob.png");
		background-size: cover;
		background-position: center center;
		background-repeat: none;
	}
	@include mq("phone-small", "max"){
		flex-direction: column;
		padding: 15px;
	}
	.product-info {
    	text-align: center;
		display: flex;
		flex-flow: column nowrap;
		max-width: 350px;
		margin: auto auto;
		justify-content: center;
		align-items: center;

		@include mq("600-pixles", "max"){
			margin: 0 20px;
		}
    }

 	> div {
    	display: flex;
    	flex: 2;
    	align-content: center;
    	justify-content: center;
		&:first-child{
			img{
				display: none;
				align-self: center;
			}
		}
		@include mq("tablet-small", "max"){
		    width: 100%;
		    height: 100%;
		}

		@include mq("600-pixles", "max"){
			&:first-child{
				flex: 1;
				img{
					display: flex;
				}
			}
		}
    }

	.product-info-bg{
		background: white;
		padding: 24px;
		margin-bottom: 24px;

		@include mq("600-pixles", "max") {
			padding: 12px;
			margin: 24px 0px;
		}

	    h1 {
			font-family: $standard-font-face;
			color: #000000;
			font-size: 42px;  
			font-weight: 800;
			line-height: 48px;
			margin: 0px;

			@include mq("tablet", "max") {
				font-size: 34px;
				line-height: 1;
			}
			@include mq("600-pixles", "max"){
				font-size: 30px;
				line-height: 1;
			}
			@include mq("phone-wide", "max"){
				font-size: 28px;
				line-height: 1;
			}
			@include mq("phone", "max"){
				font-size: 18px;
				line-height: 1;
			}
		}
	}
}

.home .product-info--left {
  @include mq("tablet", "min"){
	    align-self: flex-start !important;
	    margin-right: 70px;
	}
}	


