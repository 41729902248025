/*
 * Flex UX Framework
 *
 * Filename: item-romance-desktop.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product-description-tabs {
    padding: 10px 20px;
    margin-top: 25px;
	background: white;
	box-sizing: border-box; 
	border: 1px solid #EBEBEB;
	border-radius: 15px;

	@include mq("tablet", "max") {
	    margin-top: 10px; 
	}
	@include mq("tablet", "max") {
	    padding: 0 10px;
	}

	.mdl-tabs{

    	&__tab{

    		padding: 0px;
		    transition: background 0.15s;
		    font-size: 15px;
		    font-weight: 700;
		    font-family: $secondary-font-face;
		    border: 1px solid #F2F2F2;
		    background-color: #FFF;
		    height: 48px;
		    border: 0;
			text-transform: capitalize;

    		@include mq("phone-wide", "max"){
    			padding: 0 12px;
    		}

			&-bar {
	    		justify-content: flex-start;
	    		font-size: 14px;

	    		.mdl-tabs__tab {
	    			text-align: left;
	    			text-decoration: none; 
	    			color: #000 !important; 
	    			font-size: 15px;
					font-family: $standard-font-face;
					font-weight: 700;
					text-transform: uppercase;
					cursor: default;

		    		&.is-active{
			    		&:after{
				    		background: $brand-color; 
				    		height: 3px;
				    	}
				    }
	    		}
	    	}


    	}

    	&__panel {
		    font-family: $secondary-font-face;
			line-height: 1.8;
			letter-spacing: 1px;
			padding: 20px 0px 30px;

			@include mq("tablet", "max"){
				line-height: 2;
			}

			p {
				line-height: 21px;
				margin: 0px;
				font-family: $secondary-font-face;
				font-size: 15px;
				color: $primary-font-color;
			}
    	}
	}
}

.product-description-accordions {
	p {
		font-size: 14px;
	}
}