/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v1{
 	main{
	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

				#featured-1{
					display: flex;
					justify-content: center;
					padding-bottom: 35px;
					flex-direction: column;
					align-items: center;

					.holiday-banner {
						width: calc(100% - 2rem);
						max-width: calc(962px - 2rem);
						background-image: url("./../images/holiday-banner-bg.png");
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						border-radius: 15px;
						display: flex;
						justify-content: space-between;
						margin-top: 45px;

						h2 {
							font-size: 30px;
							font-weight: 700;
							color: #fff;
							text-transform: uppercase;
							margin-bottom: 29px;
						}

						.text-wrapper {
							padding: 24px;
							max-width: 426px;
						}

						.btn--banner {
							width: 140px;
							text-decoration: none;
						}

						.image-wrapper {
							background-image: url("./../images/banner-items.png");
							background-repeat: no-repeat;
							background-size: cover;
							width: 54%;
							border-radius: 15px;
						}

						@include mq("phone-wide", "max") {
							position: relative;

							.text-wrapper {
								z-index: 2;
								padding: 16px;
							}

							h2 {
								font-size: calc(36px - 1.9vw);
							}

							.image-wrapper {
								background-image: url("./../images/banner-items-mob.png");
								background-size: cover;
								position: absolute;
								top: 0;
								bottom: 0;
								right: 0;
								width: 229px;
							}
						}
					}

					.shop-now-product-card{
						display: flex;
						align-items: center;
						justify-content: space-around;
						img {
							width: 141px;
							height: 141px;
						}
						button {
							width: 140px;
						}
					}
					.featured-1-wrapper{
						max-width: 1000px;
					}
					h1 {
						text-align: center;
						padding-top: 14px;
						margin-bottom: 10px;
						margin-left: 15px;
						font-weight: 700;
						font-family: $secondary-font-face;
						color: #000;
						font-size: 36px;
						@media screen and (max-width: 760px) {
							text-align: center;
							margin-left: 0;
							font-size: 30px;
						}
					}

				.product-list{ 
					margin: 0px;  
					gap: 25px;
					padding: 1rem;
					@media screen and (max-width: 480px){
						gap: 12px;
					}
					.product-card{
						min-height: 230px;  
						flex-basis: 20%;
						border-radius: 15px;
						min-width: 180px;
						margin: 0px;
						border: 1px solid #EBEBEB;
						border-right: 0px;

						@include mq("850-pixels", "max") {
							flex-basis: 45%;
						}

						// &:nth-child(n+5), 
						// &:nth-child(n+5) {
						// 	@include mq('tablet', 'max'){
						// 	    display: none;
						// 	}
						// } 

						// @include mq('tablet-small', 'max'){
						// 	min-width: 50%;
						// 	}

						// @include mq("desktop-med", "max") { 
						// 	&:nth-of-type(n + 5) { 
						// 		display: none; 
						// 	}
						// }

						// @include mq("desktop-wide", "min") {   //1374px
                            
                        //     max-width: none !important; 

					    // }
                         
                      
						// &:nth-of-type(n + 6) {
						// 	@include mq("tablet", "min"){
                        //         display: none;  
						// 	}     
						// }   

						// @include mq("phone-wide", "max"){
						// 	min-width: 160px;
						// }

						// .product-image{
						// 	flex:inherit;
						// }

						// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						//      flex-basis: auto;
						// }
					}
				}
 
				button{
					width: 100%;
					max-width: 125px;
					align-self: center;
				}
			} 

			.about-us{
				background: none;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}

 	}
 }