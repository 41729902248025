/*
 * Flex UX Framework
 *
 * Filename: item-shipping-calendar.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.product-description-text{
	@include mq("tablet", "max"){
		line-height: 2;
	}

	table{
		.mdl-data-table__cell--non-numeric{
			text-align: left !important;
		    text-transform: capitalize; 
		   	font-size: 13px;
		}
	   	td{
	   		color: $brand-color-secondary;
	   	}
	}
}

.product-shipping-info {

	&-container {
    display: flex;
    justify-content: flex-start;
    padding: 30px 0 10px;
	}
    
    &-icon.material-icons {
    position: relative;
    left: -2px;
    font-size: 36px;
    margin-right: 14px;
    color: $brand-color-secondary !important;
	}

	&-text p {
    font-size: 13px;
    line-height: 1.25;
    margin-bottom: 11px;
	}
}
.product-shipping-in-hands {

	&-container {
	    color: $brand-color-secondary;
	    display: flex;
	    align-items: center;
	    padding: 20px 16px 20px 0;
	}

	&-icon.material-icons {
	    font-size: 36px;
	    margin-right: 14px;
	}

	&-text {
		font-weight: 600;
	    font-size: 16px;
	    text-transform: uppercase;

	    .in-hands-text, 
		.in-hands-title {
    		float: left;
    		clear: both;
    		font-weight: 700;
			line-height: 1.2;

    		sup {
    			font-size: 10px;
			    position: relative;
			    top: 2px;
			    font-weight: 600;
    		}
		}
	}
}


.mdl-data-table {
    border: none;
    width: 100%;
    font-size: 14px;

    tbody{
    	tr{
		    &:hover{
	    		background-color: $primary-background-color;
	    	}
	    }
    }

    tr, td, th {
	    height: 15px !important;
	    padding: 2px 5px;
	}

    th {
	    border-top: 1px solid #EBEBEB;
	    border-bottom: 1px solid #EBEBEB;
	    color: $primary-font-color;
	}

	tr {
		height: 15px !important;
	    padding: 2px 5px;
	}

	&__cell--colored {
        color: $brand-color; 
	}

	td:first-of-type,
	th:first-of-type {
    	padding-left: 24px;
    	font-weight: 700;
	}

	th:first-of-type {
	    padding-left: 0;
	}

	td:last-of-type, 
	th:last-of-type {
    	padding: 0;
	}
}

