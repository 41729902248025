/*
 * Flex UX Framework
 *
 * Filename: item-suggested-products.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.suggested-products {

	@include mq("phone-wide", "max") {
		padding-bottom: 25px;
	}

	.suggested-products-header-container {
		.suggested-products-header{
		    text-transform: capitalize;
		    text-align: center;
		    color: #000;
		    font-weight: 700;
		    font-size: 28px;
			font-family: $secondary-font-face;
			margin-bottom: 10px;
		}
		
	    padding: 30px 0px 12px 0px;
	

	    @include mq("tablet", "max") {
	    	text-align: center;
    		padding: 20px 0 14px;
	    }
	}

	.grid {
	    justify-content: center !important;
	}
}