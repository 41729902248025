/*
 * Flex UX Framework
 *
 * Filename: checkout-split-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
    .checkout-split-row {
        border-bottom: none;
        margin-bottom: 0px;


        .mdl-textfield {
            padding: 10px 0px;
        }

        @include mq("tablet", "min") {
            padding: 20px;
        }

        &--single-split {
            @include mq("tablet", "max") {
                flex-flow: column;
            }
        }

        &.checkout-split-row--controls {
            flex: 1;
            // justify-content: flex-end;


            // &:first-of-type() {
            // 	justify-content: flex-start; 
            // } 

            @include mq("tablet", "max") {
                padding: 5px 10px;
            }
        }

        .checkout-split-cell--controls-top {
            margin-bottom: 0px;
            justify-content: flex-start;
        }

        .checkout-split-cell--controls-bottom {
            justify-content: flex-end;
        }

        .checkout-split-cell--controls-top,
        .checkout-split-cell--controls-bottom {

            flex: 1 1 100%;

            @include mq("tablet", "max") {
                flex-flow: column;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                .mdl-button {
                    min-width: 250px;
                }
            }

            button:not(:last-child) {
                @include mq("tablet", "min") {
                    margin-right: 20px;
                }

                @include mq("tablet", "max") {
                    margin-bottom: 10px;
                }
            }

        }
    }

    .checkout-split-controls {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            min-width: 250px;
        }

        @include mq("tablet", "max") {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 10px;
        }
    }

	.mdl-textfield.is-focused .mdl-textfield__label:after {
		width: calc(100%);
		left: 0px;
	}

    .mdl-textfield__label {
        &:after {
            bottom: 10px;
        }
    }
}