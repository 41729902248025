// /*
//  * Flex UX Framework
//  *
//  * Filename: osano.scss
//  * Type:     Partial Stylesheet
//  * 
//  * Copyright (C) 2019 Geiger, All Rights Reserved
//  */ 

// .flex-break {
// 	width: 100%;
// }

// .osano-cm-accept {
// 	background-color: #414445;
// 	color: #FFFFFF;
// 	margin: 4px 0px 4px 0px;
// 	border: none!important;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: 600;
// 	font-size: 16px;
// 	letter-spacing: 0;
// 	line-height: 24px;
// 	text-align: center;
// }

// .osano-cm-accept:hover {
// 	background-color: #343637;
// }

// .osano-cm-deny {
// 	background-color: #DADDDE;
// 	color: #414445;
// 	margin: 4px 0px 4px 0px;
// 	border: none!important;
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 16px;
//   	font-weight: 600;
//   	letter-spacing: 0;
//   	line-height: 24px;
//   	text-align: center;
// }

// .osano-cm-deny:hover {
// 	background-color: #c4c7c8;
// }

// .osano-cm-buttons {
// 	flex-direction: column;
// }

// .osano-cm-dialog {
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 15px;
// 	font-weight: 400;
// 	color: #181A1B;
// 	line-height: 1.5em;
// 	background-color: #FFFFFF;
//   	box-shadow: 0 -10px 20px 0 rgba(0,0,0,0.1);
// 	border-top: 1px solid #DADDDE;
// }

// a.osano-cm-link {
// 	font-size: 0;
// 	text-decoration: none;
// 	color: #176F95;
// 	line-height: 1.5em;
// 	@include mq("tablet", "max"){
// 		margin-bottom: 16px;
// 	}
// }

// a.osano-cm-link:hover {
// 	color: #0D3E53;
// }

// .osano-cm-content {
// 	@include mq("desktop-wide", "max"){
// 		max-width: 75%;
// 	}
// }

// .osano-cm-message:after {
// 	content: "\A";
// 	white-space: pre;
// }

// a.osano-cm-link:after {
// 	content: 'Privacy Policy';
// 	text-decoration: underline;
// 	font-size: 15px;
// }

// .osano-cm-widget { 
// 	display: none; 
// } 

// .osano-footer {
// 	width: 100%;
// 	height: 30px; 
// 	display: flex;
// 	background-color: #414445;
// 	border-top: solid 1px #A2AAAD;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: 400;
// 	color: #FFFFFF;
// 	font-size: 14px;
// 	letter-spacing: 0;
// 	line-height: 0;
// 	justify-content: flex-end; 
// }

// .osano-footer > .footer-nav-menu {
// 	text-align: right;
// 	line-height: 10px;
// 	padding-right: 10px;
// 	@include mq("tablet", "max"){
// 		text-align: center;
// 		padding-top: 0;
// 		padding-right: 0;
// 		width: 100%; 
// 	}
// }

// /*
// .osano-footer > .footer-nav-menu > li {
// 	@include mq("tablet", "max"){
// 		padding-top: 18px;
// 		padding-bottom: 18px;
// 	}
// }
// */

// .osano-footer > .footer-nav-menu a {
// 	font-weight: 400;
// 	text-decoration: none;
// 	color: #ffffff;
// }

// .osano-footer > .footer-nav-menu li:hover {
// 	color: #DADDDE;
// 	text-decoration: underline;
// 	background: none; 
// }

// .js_osano-footer-preferences {
// 	font-weight: 400;
// 	color: #ffffff;
// }

// .osano-cm-info {
//    color: #181A1B;

//    p {
//        font-family: Helvetica,Arial,Hiragino Sans GB,STXihei,Microsoft YaHei,WenQuanYi Micro Hei,Hind,MS Gothic,Apple SD Gothic Neo,NanumBarunGothic,sans-serif;  
//    } 
	
// }

// .osano-cm-info-dialog-header__header  {
// 	font-size: 16px;
// }

// .osano-cm-info-dialog-header__description {
// 	font-size: 13px;
// 	line-height: 1.5;
// 	margin-bottom: 30px;
// }

// .osano-cm-drawer-toggle .osano-cm-label {
// 	font-size: 14px;
// }

// .osano-cm-description {
// 	font-size: 12.5px;
// 	line-height: 1.5;

// 	p {
// 		font-size: 12.5px;
// 		line-height: 1.5; 
// 		color: #181A1B; 
// 	    font-family: Helvetica,Arial,Hiragino Sans GB,STXihei,Microsoft YaHei,WenQuanYi Micro Hei,Hind,MS Gothic,Apple SD Gothic Neo,NanumBarunGothic,sans-serif; 
// 	} 
	
// }

// .osano-cm-disclosure__toggle {
// 	font-size: 12.5px;
// 	color: #176F95;
// 	text-decoration: underline;
// }

// .osano-cm-disclosure__toggle:hover {
// 	color: #0D3E53;
// } 

// .osano-cm-save {
// 	background-color: #414445;
// 	color: #FFFFFF;
// 	width: 100%;
// 	margin: 4px 0px 4px 0px;
// 	border: none!important;
// 	font-weight: 600;
// 	font-size: 16px;
// 	letter-spacing: 0;
// 	line-height: 24px;
// 	text-align: center;
// }

// .osano-cm-save:hover {
// 	background-color: #343637;
// }

// .osano-cm-powered-by {
// 	display: none;
// } 
