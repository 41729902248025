.home-big-feature {
    background-color: white;
    .feature-content{

    max-width: 1000px;
    margin: auto;
    padding: 1rem;
    box-sizing: border-box;
    }
    h2 {
        color: $main-font-color;
        font-size: 36px;
        font-weight: 600;
        text-transform: capitalize;
        font-family: $secondary-font-face;
        margin-bottom: 25px;
        margin-top: 60px;
    }

    .cat-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        gap: 25px;
        @media screen and (max-width: 480px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }

    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        height: 106px;
        background-color: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        flex-direction: column;
        border: 1px solid #e1e1e1;
        position: relative;
        opacity: 40%;
        padding: 20px;
        pointer-events: none;
        &.active{
            pointer-events: all;
            opacity: 100%;
            &::before, &::after{
                display: none;
            }
        }
        &::before{
            position: absolute;
            top: 0;
            right: 0;
            content: 'coming soon';
            font-family: $standard-font-face;
            padding: 0px 3px;
            z-index: 1;
            text-transform: uppercase;
            background: grey;
            color: white;
            pointer-events: none;
        }
        &::after{
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            backdrop-filter: grayscale(100%);
        }
        
        img{
            aspect-ratio: 1 / 1;
            max-width: 100%;
            width: 100%;
            object-fit: contain;
        }

        h1 {
            font-weight: 400;
            font-family: $bold-font-face;
            font-size: 30px;
            text-transform: capitalize;
            margin: 21px 21px 0;
            color: white;
            padding-bottom: 17px;
        }

        .borderBox {
            height:3px;
            width: 42px;
            background-color: #FFF;
            margin-left: 20px;
        }
    }
}

