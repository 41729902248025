/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-multi-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
.checkout-address-controls .mdl-button:not(:last-child) {
	@include mq("tablet", "max"){
	    margin-bottom: 10px;
	}
}

.checkout-address-controls-container {
	display: flex;

	@include mq("tablet", "min"){
    	padding: 20px;
	}

	@include mq("tablet", "max"){
	   	padding: 15px; 
	    flex-wrap: wrap;
	    flex-flow: column;
	    align-items: center;
	}

	.checkout-address-controls-split {
	    display: flex;
	    justify-content: flex-start;
	    flex: 2 2;

	    @include mq("tablet", "max"){
		    flex-wrap: wrap;
		    flex-flow: column;
			width: 100%;
			justify-content: center;
			align-items: center;
		}

		button{
			margin-bottom: 10px;

			@include mq("tablet", "max") {
				width: 100%;
				max-width: 250px;
			}
		}

	    button:not(:last-child) {
	    	@include mq("tablet", "min"){
		    	margin-right: 20px;
		    }
		}
	}

	.checkout-address-continue-split {
	    display: flex;
	    flex: 1 1;
	    justify-content: center;
		width: 100%;

	    button {
		    width: 261.46px;

			@include mq("tablet", "max") {
				width: 100%;
			}
		}	
	}
}