/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.footer-nav-menu {
	padding: 0px;
	margin: 0px;
	height: 100%;
	
	@include mq("tablet", "min") {
		display: flex;
		align-items: center;
		flex-direction: row;
	}	 

	li {
		display: inline-flex;
		list-style-type: none;
		font-size: 14px;
	
		cursor: pointer;
		font-weight: 600;
		height: 100%;

    	@include mq("tablet", "min") {
			align-items: center;
    	} 

		@include mq("tablet", "max") {
			// margin: 10px 0px; 
		}
	}
}