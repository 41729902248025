/*
 * Flex UX Framework
 *
 * Filename: general.scss
 * Type:     General Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

//Universal Element Styles

* {
    box-sizing: border-box;
}

html {
    height: 100%;
    color: $primary-font-color;
    border: 0;
    margin: 0;
    padding: 0;

}

body {
    width: 100%;
    height:  auto !important;
    min-height: 100%;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    font-family: $secondary-font-face;
    font-size: 15px;
    line-height: 1.5em; 
   
}

* {
    // font-weight: normal;
}

input {
    -webkit-appearance: none;
    font-family: $standard-font-face;
    color: #000;
    line-height: 1;
    font-size: 13px;
}

// Hide X from search bar for IE/Chrome.
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

body, input {
    @include mq("tablet", "max") {
        font-size: 13px;
    }
}

a { 
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;
    font-weight: 400;
    
    &:hover{
        color: $link-color-hover;  
    } 
}

p {
	margin: 0px;
	color: $primary-font-color;
	font-family: $secondary-font-face;
    font-size: 15px;
    line-height: 1.5em; 
}

img {
    max-width: 100%;
    max-height: 100%;   
}

h1 {
    line-height: 1.15;
    font-family: $secondary-font-face;
    color: $h1-font-color;
    font-weight: 700;
    font-size: $h1-font-size;
    letter-spacing: initial;
    text-transform: capitalize;
    @media screen and (max-width: 768px){
        font-size: 30px;
    }
}   

h2 {
    margin: 0;
    line-height: 1.2;
    font-family: $secondary-font-face;
    color: black;
    letter-spacing: 0;
    font-weight: 500;
    font-size: $h2-font-size;
    text-transform: capitalize;

    @include mq("tablet", "max") {
        font-size: 20px;
    }
}

main {
    background-color: #fff;
}


@include mq('phone-wide', 'max') {
    .desktop-only {
        display: none !important;
    }
}

@include mq('phone-wide', 'min') {
    .mobile-only {
        display: none !important;
    }
}

//Universal Page Styles
.page-body {
    max-width: $body-max-width;
    padding: 12px 12px 50px 12px;
    margin: auto auto;
    //margin-bottom: 50px;

    // @include mq("tablet", "max") {
    //     margin-bottom: 50px;
    // }

    // &>h1:first-of-type {
    //     font-weight: 900;
    //     padding-top: 10px;
    //     padding-bottom: 20px;
    //     text-transform: capitalize;
    //     font-size: 30px;
    //     line-height: 34px;
    //     margin-bottom: 20px;
    //     color: $primary-font-color;
    //     margin: 10px 0 0;
    // }
}

.product-card, .product-cell {
    margin: 0 12px 24px;

    @include mq("phone-wide", "max") {
        margin: 6px;
    }

    @include mq("phone", "max") {
        margin: 5px;
    }
}

.product-list {
    margin: 0 -12px;

    @include mq("tablet", "max") {
        justify-content: center;
    }
    @include mq("phone-wide", "max") {
        margin: 0 -6px;
    }
    @include mq("phone", "max") {
        margin: 0;
    }
}

.btn--light, .btn-main, .gift-certificate-button {
    background-color: #FFF;
    border: 2px solid $brand-color;
    height: 45px;
    color: #000;
    &:hover {
        color: #242424;
        background-color: #EBEBEB;   
    }
}

// .btn--dark {
//     background: black;
//     color: white;
// }

// .btn--dark, .btn--light, .btn-main {
//     padding: 3px 51px;
//     font-size: 16px;
//     text-transform: capitalize;
//     border-radius: 0px; 
//     max-width: 300px; 
//     border-radius: 25px;
// }

.btn--primary, .btn--secondary, .checkout-btn, .btn--light, #gift-button, .btn--banner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $bold-font-face !important;
    font-size: clamp(14px, 3vw, 15px);
    line-height: 0;
    padding: 0px 30px 0px 30px;
    transition: none;
    height: 45px;
    font-weight: 700;
    max-width: 320px !important;
    white-space: nowrap;
    border: 2px solid;  
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 25px;

    @supports (-ms-ime-align:auto) {
        color: red !important;
    }
}

.btn--light{
    border: 2px solid #1CACE9;
    background-color: #FFF;
    color: #000;
    
    &:hover {
        background-color: #EBEBEB;
        color: #242424;
        cursor: pointer;
    }
}

.btn--primary, .checkout-btn {
    background-color: $brand-color-btn-primary;
    border: unset;
    color: #FFF !important;


    &:hover {
        background-color: $brand-color-btn-primary-hover !important;
        color: black;
        cursor: pointer;
    }
}

.start-shopping {
    box-shadow: 0px 0px 25px;
    font-size: 16px;
    border-radius: 0px;
}

.btn--secondary, #gift-button{
    background-color: $brand-color-btn-secondary;
    color: #FFF;
    border: unset;
    &:hover {
        cursor: pointer;
        background-color: $brand-color-btn-secondary-hover;
        color: #FFF;
    }
}

.btn--banner {
    background-color: $brand-color-secondary;
    color: #FFF;
    border: unset;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        background-color: #A60823;
        color: #FFF;
    }
}

// Material Design Overrides
.mdl-layout--fixed-drawer>.mdl-layout__drawer-button {
     @include mq("tablet", "min") {
        display: none;
     }
}

.mdl-layout__container {
    pointer-events: none;
}

.mdl-textfield{

    .mdl-textfield__label:after {
        background-color: $brand-color-secondary !important;
        height: 1px;
    } 
    &--floating-label{
        .mdl-textfield__label{
            color: $brand-color-secondary !important;
        }
    }

    &--floating-label.is-dirty, &--floating-label.is-focused{
        .mdl-textfield__label{
            color: $brand-color-secondary !important;
        }
    }
 
    &__input {
        padding-left: 3px;
        font-family: $secondary-font-face;
        font-size: 13px !important;
    }
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple{
    background-color: #000;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border: 2px solid $brand-color;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline{
    background-color: $brand-color-secondary;
}

.checkout{
main[style]{
    min-height: calc(100vh - 96px) !important;
}

    .page-body{
        // h1:first-of-type{
        //     margin-left: 10px;
        // }  
    }

    &-main-top {
        background: #fff;
    }

    &-side-top {
        background: #fff!important;
    }
}


/* 
 * Modal Dialogs
 */

.mdl-dialog {
    width: calc(100% - 80px);
    max-width: 760px;
    
    p{
      color:$primary-font-color;
    }

    a{
      color: $brand-color-secondary;
      text-decoration: underline;
    }

    .close-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: 11px 11px 20px 20px;
    }

    .modal-title {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #efeff1;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 55px;
        font-size: 23px;
        text-transform: uppercase;
        line-height: 1.75;
        height: auto;

        .modal-title{
            &__major {
                flex: 1 1 100%;
                align-self: flex-end;
                line-height: 1;
                text-align: center;
                text-transform: capitalize;
                font-family: $secondary-font-face; 
                color: #333;
                font-size: 23px;
                font-weight: 500;
                margin-bottom: 10px; 
            }
            &__minor { 
                flex: 1 1 100%;
                align-self: flex-start;
                font-size: 13px;
                font-weight: normal;
                color: #161A1C;
                text-transform: none; 
                text-align: center; 
            }
        }

        .mdl-dialog__content{
          padding: 0 0 24px 24px;
          .material-icons{
            font-size: 18px;
          }
          p{
            font-size: 14px;
            line-height: 20px;
          }
        }
    }

      .mdl-dialog__actions{
        display: flex;
        flex-direction: column;
        align-content: center;
        .mdl-button{
          min-height: 45px;
         // height: auto;
        }

        .mdl-checkbox__label {
            color: $primary-font-color;
            line-height: 1.25em;
            white-space: nowrap;
            font-size: 14px;

           @include mq('phone-wide', 'max') {
           }
        }

        .mdl-cell {
            justify-content: center;
            align-items: center;
            display: flex;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin: 0 auto;
              }
        } 


        label.mdl-checkbox.mdl-js-checkbox.is-upgraded {
            width: 205px;

            @include mq("phone-wide", "max")  {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
            }
        } 



      }
}

.select2-dropdown-container {

    .select2 {
        outline-style: none;
    }
    .country-options-container .select2-container, 
    .province-options-container .select2-container {
        flex: 1 1 100%;
        width: 100% !important;
        padding-top: 20px;

        .select2-selection {
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-radius: 0px;
        } 
    }

    .select2-container {
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
    }
}

.select2-dropdown-container .select2-container--default .select2-selection--single {
    border-color: rgba(0,0,0,.12);
}

.dialog-address-modal {

    .close-btn {
        color: $brand-color;
    }

    .mdl-dialog__content {
        padding: 20px 4px 24px;
    }
    
    @include mq("phone", "max") {
        width: calc(100% - 45px);
    }

    .select2-selection__arrow {
        top: 21px !important;
    } 

    .select2-dropdown-container {
        padding-bottom: 20px;
    }

    .mdl-textfield {
        width: 100%;
    }
    .add-button-container {
        display: flex;
        justify-content: center; 
    }

    .mdl-dialog__actions {
        padding: 8px;

        @include mq("tablet", "max"){ 
        justify-content: center; 
        }

        .mdl-cell {
            @include mq("tablet", "max"){
                display: flex;
                justify-content: center;
            }
        }
    }
}

// .mdl-radio__outer-circle {
//     border: 2px solid $brand-color;
// }

.mdl-radio.is-checked {
    .mdl-radio__outer-circle {
        border: 2px solid $brand-color;
    }
    .mdl-radio__inner-circle {
        background: $brand-color;
    }
}



.cookie-policy-wrapper {
    width: 100% !important;
}

.cookie-policy-controls__bottom > button {
    width: auto !important;
}

.cookie-policy-container {
    width: 100% !important;
}

.forgot-password {
    main{
        background: white !important;
    }
    .page-body {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

        form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .mdl-textfield__label{
                top: 15px;
            }
        }

        button{
            margin: 5px 20px;
        }
    }

    .emailIcon{
        position: relative;
        top: 6px;        
    }
}

.forgotPW{
    position: relative;
    margin-top: 15px;   

     @include mq("tablet", "min") {
        margin-top: 20px;
     } 
}

main {
    flex: 1 0 auto;
   
}

.mdl-menu__item{
    &:hover {
        background-color:#F8F8F8;
    }

    a:hover{
        color: $brand-color;
    }
}

.register, .forgot-password{
    text-align: center;
}

.registerBody{
    background-color: #fff !important;
}

.login .mdl-layout__content{
    padding: 12px 12px 50px 12px;    
}


.checkout .icon-wrapper{
    display: none;
}

.product-detail {
    main[style]{
        min-height: calc(100vh - 96px) !important;
    }
}


 

// .v1-home .page-header {
//     padding-top: calc(#{$header-main-height} + 10px);
//     background: #fff;
//     display: block;

//     @include mq("phone-smallest", "min") {
//       padding-top: 51px !important; 
//     }

//     @include mq("phone-wide", "min") {
//         padding-top: calc(#{$header-main-height} - 10px) !important;
//          padding-top: calc(#{$header-main-height} - 28px) !important;
//     }   

//     @include mq("tablet", "min") {
//             padding-top: 0px !important;   
//     }       

//     @include mq("tablet", "min") {

//     }

//     @include mq("tablet", "max") {
//         flex-flow: column-reverse nowrap;
//     }
// }

.page-header{
    margin-top: $header-height;

    @include mq("tablet", "max") {
        margin-top: $header-tablet-height;
    }
}

.category-list .page-header{
    /*display: none;*/
    padding-top: 0px !important;
    
    /*@include mq("phone-wide", "min") {
            padding-top: 56px !important;
    }*/
}



.checkout-basket-promo--btn{
    /*margin-top: 11px;*/
}

.wishlist {
    .page-body {
        height: 100vh; 
    }
}

.grecaptcha-badge {
    z-index: 99;
    bottom: 100px !important;
}

.mdl-button--search {
    left: 0;
}

.address-cards-grid {
    .mdl-card--border {
        right: 8px;
        top: 8px;
    }
}

.mdl-textfield--expandable {
    padding: 0;
    .mdl-textfield__expandable-holder{
        max-width: 0;
        overflow-x: hidden;
    }
    &.is-focused, &.is-dirty{
        .mdl-textfield__expandable-holder{
            max-width: 600px;
        }
    }
}