
/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.dropdown-container {
  margin: 6px;
  margin-top: 12px;
}
