/*
 * Flex UX Framework
 *
 * Filename: checkout-payment-cc.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-container .checkout-bottom--gateways .gateway-tabs .payment-columns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    border-radius: 15px;
}

.checkout-container .checkout-bottom--gateways .payment-column-one, 
.checkout-container .checkout-bottom--gateways .payment-column-two {
    box-sizing: border-box;
    max-width: 100%
}

.checkout-container .checkout-bottom--gateways .payment-column-one {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 55 55 55%;
    border-right: 1px solid #efeff1;
    .mdl-textfield__input {
    	color: $primary-font-color; 
	} 
	.mdl-textfield__label {
		color: $brand-color;
	}
}

.checkout-container h3 {
    font-size: 14px;
    line-height: 18px;
    margin: 0px 0px 10px 0px;
    flex: 1 1 100%;
    font-weight: 600; 
    font-family: $standard-font-face;
}
.checkout-container .checkout-bottom--gateways .gateway-tabs .billing-adjust {
    flex: 2 2 100%;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5em;
    span {
        text-decoration: underline;
        color: $link-color;
        &:hover {
            color: $link-color-hover;
        }
    }
}

.checkout-container .checkout-bottom--gateways .payment-column-two {
    flex: 45 45 45%;
}

.checkout-container .checkout-bottom--gateways .payment-column-two .payment-column-two__line {
    display: flex;
    width: 100%;
    justify-content: center;

    .payment-column-two__line--icon {
        position: relative;
        line-height: 61px;
        left: -4px;

        @include mq('desktop', 'max') {
            display: none;
        }
    }
}

.checkout-container .checkout-bottom--gateways {
	.mdl-textfield__label {
		font-size: 13px; 
        color: #737677;
	}
}
.checkout-container .checkout-bottom--gateways .payment-column-two--plp .textfield-cc  {
    flex: 1 1 100%;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 0;

    &.is-dirty{
        .mdl-textfield__label {
            color: $brand-color;
        }
    }

    &.is-focused{
        .mdl-textfield__label {
            color: $brand-color;
        }
    }
    &.is-disabled{
        .mdl-textfield__input {
            color: #333 !important;
        }

        .mdl-textfield__label {
            color: $brand-color;
        }
    }
}

.checkout-container .checkout-bottom--gateways .payment-column-two .payment-column-two-bottom {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    flex-flow: column;
    button{
        min-width: 260px;
    }
}
.checkout-container .checkout-bottom--gateways .payment-column-two--plp .checkbox-cc {
    margin-bottom: 20px;
    margin-left: 20px;
    .mdl-checkbox__label{
        font-size: 14px;
        font-weight: 700;

    }

}

.checkout-container .checkout-bottom--gateways .payment-column-two--plp {
    .mdl-textfield .mdl-textfield__label:after {
        bottom: 20px;
    }

    .mdl-textfield.is-focused .mdl-textfield__label:after {
        // width: calc(100% - 20px);
		// left: 10px;
    }
}

.checkout-container .checkout-bottom--gateways .payment-column-two .payment-column-two-top, 
.checkout-container .checkout-bottom--gateways .payment-column-two .payment-column-two-bottom {
    // height: 50%;
}

.checkout-container .checkout-bottom--gateways .payment-column-two .payment-column-two-top .mdl-textfield__label{
    padding-left: 10px;
    padding-right: 10px;
}

// Credit card field area
.checkout-container .plp-wrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    button {
    	background: white; 
    }
}
.checkout-container .plp-wrapper iframe#plp-iframe {
    height: 320px;
    width: 370px;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: #FFFFFF;
}

@include mq("tablet", "max") {
    .checkout-container .checkout-bottom--gateways .payment-column-one h3, 
    .checkout-container .checkout-bottom--gateways .payment-column-two h3 {
        text-align: left;
        padding-left: 5%;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .checkout-container .checkout-bottom--gateways .payment-column-one, 
    .checkout-container .checkout-bottom--gateways .payment-column-two {
        flex: 1 1 100%;
    }
}


@include mq("tablet", "min") {
	.checkout-container .checkout-bottom--gateways .payment-column-one, 
	.checkout-container .checkout-bottom--gateways .payment-column-two {
	    padding-left: 20px;
	    padding-right: 20px;
	    margin-top: 20px;
	    margin-bottom: 20px;
	}
	.checkout-container .checkout-bottom--gateways .payment-column-one {
	    max-width: 50% !important;
	}
	.checkout-container .checkout-bottom--gateways .gateway-tabs .textfield-payment {
    	flex: 1 1 50%;
	}
}