/*
 * Flex UX Framework
 *
 * Filename: breadcrumbs.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.breadcrumb {
    height: 39px;
    line-height: 42px;
    background: #FFF;
    font-size: 13px;
    font-weight: 400;
    font-family: $standard-font-face; 
    overflow: hidden;
    position: relative;
    padding-left: 27px;  
    border-bottom: 1px solid #EBEBEB;
    white-space: normal; 
    text-overflow: clip;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    a:first-of-type {
        pointer-events: none;
    }

    a , span{
        margin-right: 8px;
        color: #757676;
        text-decoration: none;
        text-transform: capitalize;
    }

    span:last-of-type {
        color: $brand-color-secondary;
    }

    @include mq("desktop", "max"){
        padding: 0 15px;
    }


}

