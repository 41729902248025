/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.category-banner{
	height: 130px;
	max-height: 130px;
	position: relative;
	background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(235, 235, 235) 100%) !important;
	.logo-wrapper {
		width: 100%;
		height: 100%;
	}
	.category-banner-title {
		text-align: center;
		font-size: 50px;
		font-weight: 700;
		margin: 0;
		padding-top: 20px;
		@include mq("850-pixels", "max") {
			font-size: 40px;
			padding-top: 25px;
		}
		@include mq("700-pixels", "max") {
			font-size: 36px;
			padding-top: 10px;
		}
	}
	.category-banner-subtitle {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		margin: 0;
		@include mq("850-pixels", "max") {
			font-size: 20px;
		}
		@include mq("700-pixels", "max") {
			font-size: 16px;
		}
	}
}
.prime {
	background: url("../images/category-images/primeresins.svg");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 320px 75px;
	border-bottom: 4px solid #89BE44;
	@include mq("850-pixels", "max") {
		background-size: 220px 55px;
	}
	@include mq("700-pixels", "max") {
		background-position: center bottom;
	}
}
.spc {
	// background: url("../images/category-images/spc.svg");
	background: url("../images/category-images/SPC_Logo.png");
	background-repeat: no-repeat;
	background-position: 75px center;
	background-size: 110px 110px;
	border-bottom: 4px solid $brand-color;
	@include mq("700-pixels", "max") {
		background-size: 70px 70px;
		background-position: center 50px;
	}
}
.logiball {
	background: url("../images/category-images/logiball.svg");
	background-repeat: no-repeat;
	background-position: center left;
	background-size: 250px 55px;
	border-bottom: 4px solid $brand-color;
	@include mq("700-pixels", "max") {
		background-position: center bottom;
	}
}
.dudick {
	// background: url("../images/category-images/dudick.svg");
	background: url("../images/category-images/Dudick_Logo.png");
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 200px 55px;
	border-bottom: 4px solid #F78E1E;
	@include mq("700-pixels", "max") {
		background-position: center 80%;
		background-size: 190px 50px;
	}
}

.category-list {
	main{
		background-color: $primary-background-color; 
	}

	.page-header {
		height: 0px!important;

		@include mq("phone-smallest", "min") {
			padding-top: calc(0px + 0px);
		}		

		@include mq("phone-wide", "min") {
			padding-top: calc(55px + 0px);
		}			

		@include mq("tablet-small", "min") {
			padding-top: calc(55px + 0px);
		}	

	}

	.page-body {
		font-weight: 600; 
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 25px;

		@include mq("tablet-small", "max") {	
			// margin-bottom: 12px;
		}

		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			order: 4;
			margin-right: -12px;
			overflow-x: hidden;

			@media only screen and (max-width: 1210px) {
				margin-right: 0;
			}

			@include mq('tablet', 'max') {
				margin-right: 0;
    			margin: 12px -12px 0;
			}

			@include mq("tablet-small", "max") {
				margin: 12px 0px 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 0px -6px;
				padding: 6px 12px 0px 12px;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq("phone-wide", "max") {
		    padding: 12px;
			padding-bottom: 4px;
		    margin-top: 0;
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
		height: 41px;
    	padding-bottom: 5px;
    	justify-content: flex-end;
    	
    	@include mq('tablet', 'max') {
			order: -1;
	    	flex: 2 2 100%;
	    	display: flex;
	    	justify-content: flex-end;
			margin-top: 13px;
		}	

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			flex: 4 4 100%;
    		margin-bottom: 30px;
			margin-right: -12px;
    	}

		@media only screen and (max-width: 1210px) {
			margin-right: 0;
		}

    	.title {
			flex: 1 1 50%;
			font-size: 36px;
			height: 62px;
			font-weight: 400;
			color: #000;
			font-family: $secondary-font-face;
			text-transform: capitalize;

	  		@include mq('desktop', 'max') {
	  			font-size: calc(12px + 2.22vw);
	  		}
			@include mq('tablet', 'min') {
				line-height: 62px;
			}
    	}

	}

	.product-list {
	    &:after {
	        content: "";
	        display: block;

		    @include mq('tablet', 'max') {
		        width: calc(50% - 24px);
		        flex-basis: calc(50% - 24px);
			}

		    position: relative;
		    box-sizing: border-box;
		    overflow: hidden;
		    display: flex;
		    flex-flow: column nowrap;
		    justify-content: space-around;
		    flex-basis: 270px;
		    flex: 1 0 270px;
		    margin: 0px -1px -1px 0px;
		    // padding: 20px;
		    max-width: 240px;
		    /*height: 285px;*/

	  		@include mq('tablet-small', 'min') {
	  			// max-width: 480px;
	  		}
	    }
	}
}

// .sort-by-container{
//     position: relative;
// 	right: 0px;
// 	align-self: flex-end;
// }